import React, { useRef, useState, useEffect } from 'react';
import { AddsalaryCalculationApi } from '../../../Config/api';
import { toast } from 'react-toastify';
import { CiCircleInfo } from 'react-icons/ci';

const Editpayroll = ({ show, close, salarydata,datatoparent }) => {
  const payslipRef = useRef();
  const [otherEarnings, setOtherEarnings] = useState();
  const [otherDeductions, setOtherDeductions] = useState();
  const [loader,setloader] = useState(false)

  useEffect(() => {
    if (salarydata) {
      // Initialize the state with values from salarydata
      setOtherEarnings(salarydata.other_earning || 0);
      setOtherDeductions(salarydata.other_deduction || 0);
    }
  }, [salarydata]);

  if (!show) return null;
  if (!salarydata) return null;
  const employee = salarydata;

  // Calculate updated totals
  const totalEarnings = employee.earning_salary + parseFloat(otherEarnings || 0);
  const totalDeductions = employee.deduct_salary + parseFloat(otherDeductions || 0);
  // const netPay = totalEarnings - totalDeductions;

  
  const salaryCalculation = async () => {
    setloader(true)
    // setShowConfirmModal(false);
const id = employee.register;
const idsar = [id];
    
      try {
        const data = {
          "register_id": idsar,
          "month": employee.month,
          "year": employee.year,
          "other_deduction" : parseFloat(otherDeductions || 0),
          "other_earning" : parseFloat(otherEarnings || 0),

        };
        console.log(data)
        const response = await AddsalaryCalculationApi(JSON.stringify(data));
        if (response) {
          datatoparent(response);
          close();
          // const updatedArray = new Map(response.data.calculation.map(item => [item.register_id, item]));

          // // Merge the updated data into the original array
          // const updatedEmployees = employees.map(employee =>
          //   updatedArray.has(employee.register_id)
          //     ? { ...employee, ...updatedArray.get(employee.register_id) }
          //     : employee
          // );
          // setSelectedEmployees([])
          // setSalaryselectedEmployess([])
          // setSelectAll(false)
          // fetchpayrollList();
          // setEmployees(updatedEmployees);

          toast.success('Salary added successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
        }
      }
      catch (error) {
        toast.error('Failed. Please try again.', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,

        });
      }finally{
        setloader(false)

      }
    
  }
  return (
    <div className='viewslippopup-section'>
      <div className='viewslippopup-container'>
        <span className='uploadexcel-close' onClick={close}>&times;</span>
        <div className="payslip" id="payslip" ref={payslipRef}>
          <div className="payslip-header">
            <h3>Salary Calculation</h3>
          </div>
          <p className='payslip-edit-alertshow-para'> <CiCircleInfo style={{fontSize:"16px"}}/>Users can update other earnings and other deductions directly within the input fields.</p>
          <div className="payslip-details">
            <div className='payslip-earnings'>
              <table>
                <tbody>
                  <tr>
                    <td colSpan={2}>Total Days: {employee.days_in_month}</td>
                    <td colSpan={1}>Earning Days: {employee.earning_days}</td>
                    <td colSpan={1}>Deduction Days: {employee.deduction_days}</td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Earnings</th>
                    <th colSpan={2}>Deductions</th>
                  </tr>
                  <tr>
                    <td colSpan={2}>Leave Earnings: {employee.leave_earning}</td>
                    <td colSpan={2}>Leave Deductions: {employee.leave_deduction}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Punched Count: {employee.punch_count}</td>
                    <td colSpan={2}>Missed Punch: {employee.missed_punched}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Holiday Count: {employee.holiday_count}</td>
                    <td colSpan={2}>Late Deductions: {employee.late_deduction ||0}</td>
                  </tr>
                  {(() => {
                    const flattenedList = employee.salary_compo_list.flat();
                    const earnings = flattenedList.filter(item => item.earning_deduction === 'earning');
                    const deductions = flattenedList.filter(item => item.earning_deduction === 'deduction');
                    const maxLength = Math.max(earnings.length, deductions.length);

                    return Array.from({ length: maxLength }).map((_, index) => (
                      <tr key={index}>
                        <td>{earnings[index]?.component_name || ''}</td>
                        <td>{earnings[index]?.monthly || ''}</td>
                        <td>{deductions[index]?.component_name || ''}</td>
                        <td>{deductions[index]?.monthly || ''}</td>
                      </tr>
                    ));
                  })()}
                  <tr>
                    <td><strong>Other Earnings</strong></td>
                    <td className='payslip-input-field'>
                      <input
                        type="number"
                        value={otherEarnings}
                        onChange={(e) => setOtherEarnings(e.target.value)}
                        placeholder="Enter amount"
                      />
                    </td>
                    <td><strong>Other Deductions</strong></td>
                    <td className='payslip-input-field'>
                      <input
                        type="number"
                        value={otherDeductions}
                        onChange={(e) => setOtherDeductions(e.target.value)}
                        placeholder="Enter amount"
                      />
                    </td>
                  </tr>
                  <tr className="total-row">
                    <td><strong>Total Earnings</strong></td>
                    <td><strong>{totalEarnings.toFixed(2)}</strong></td>
                    <td><strong>Total Deductions</strong></td>
                    <td><strong>{totalDeductions.toFixed(2)}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="payslip-summary-edit">
            <div>
            <h4>Summary</h4>
            <p><strong>Net Pay:</strong> ₹{totalEarnings.toFixed(2)}</p>
            </div>

            <div className='payslip-summary-editdel'>
              <button onClick={salaryCalculation} disabled={loader}>
                {loader ?<span className='spinner'></span> : "Submit" }
                
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editpayroll;
