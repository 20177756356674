import React, { useState, useEffect, useCallback } from 'react';
import { CiTrash } from 'react-icons/ci';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
// import Adddesignation from './Adddesignation';
import { getUserDetails } from '../../../Services/Storage';
import { DeleteHolidayApi, ViewHoliday } from '../../../Config/api';
// import Editdesignation from './Editdesignation';
import DeleteAlert from '../../popup/DeleteAlert';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import pageloader from '../../../Loader/pageloading/Pageloader';
import ReactPaginate from "react-paginate";
import { CompanyApi } from '../../../Config/CommonApi';
import Select from 'react-select';
import getMonthYearData from '../../Component/MonthYearList';
import Addholiday from './Addholiday';
import formatDate from '../../Component/DateConvert';

const HolidayView = () => {
  const user_details = getUserDetails();
  const [isadddepopen, setadddepopen] = useState(false);
  const [holiday, setholiday] = useState([]);
  // const [editData, setEditData] = useState([]);
  // const [iseditopen, seteditopen] = useState(false)
  const [deleteComponentId, setDeleteComponentId] = useState(null);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [totaldedcount, setdedcount] = useState(0)
  const per_page = 10;
  const [currentpage, setCurrentPage] = useState(1);
  // const [search, setSearchword] = useState("");
  const [loading, setIsloading] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [company, setCompany] = useState("");
  const [year, setYear] = useState(new Date().getFullYear()); // Default to current year
  const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, '0')); // Default to current month, formatted as two digits
  const [companyFilter, setCompanyFilter] = useState([]);


  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  // const handleSearch = (event) => {
  //   setSearchword(event.target.value);
  // }
  const handleChangeCompany = (selecteOption) => {
    setCompany(selecteOption.value);
    setCompanyFilter(selecteOption);
    setCurrentPage(1)


  };
  const openadddep = () => {
    setadddepopen(true);
  }

  const closeaddleave = () => {
    setadddepopen(false);
  }
  const handleChangeYear = (selecteOption) => {
    setYear(selecteOption.value);
    setCurrentPage(1)

  }
  const handleChangeMonth = (selecteOption) => {
    setMonth(selecteOption.value);
    setCurrentPage(1)

  }
  const { months, years } = getMonthYearData();
  const monthData = Object.keys(months).sort((a, b) => a - b).map(key => (
    {
      value: key,
      label: months[key],
    }));

  const yearData = Object.keys(years).map(key => ({
    value: years[key],
    label: years[key],
  }));

  useEffect(() => {
    const fetchCompanyData = async () => {
      const data = await CompanyApi();
      setCompanyData(data);
      setCompanyFilter(data[0]);

    };

    fetchCompanyData();
  }, [month, year]);
  const Fetchholiday = useCallback(async () => {
    try {
      setIsloading(true);

      const response = await ViewHoliday(`?register_id=${user_details.id}&company=${company}&page=${currentpage}&page_count=${per_page}&month=${month}&year=${year}`)
      if (response) {
        setholiday(response.data.get_holiday || [])
        setdedcount(response.data.page_context.total)

      }
    } catch (error) {

    } finally {
      setIsloading(false)
    }
  }, [currentpage, per_page, company, month, year, user_details.id])
  useEffect(() => {
    Fetchholiday();
  }, [Fetchholiday])

  const handleDataFromChild = (data) => {
    setholiday((prev) => [
      data,
      ...prev,
    ])
  }


  const confirmDelete = () => {
    if (deleteComponentId) {
      deletesalaryComponent(deleteComponentId);
      closeConfirmationModal();
    }
  };
  const deletesalaryComponent = async (componentId) => {
    try {
      const response = await DeleteHolidayApi(componentId);
      if (response) {
        const updatedList = holiday.filter((component) => component.id !== componentId);
        setholiday(updatedList);
      }
    }
    catch (error) {

    }
  }
  const showConfirmationModalDelete = (delid) => {
    setDeleteComponentId(delid);
    setConfirmationModalOpen(true);

  }
  const closeConfirmationModal = () => {
    setDeleteComponentId(null);
    setConfirmationModalOpen(false);
  };

  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>
                <div className='pages-subheader-sub-1'>
                  <p>Holiday
                  </p>
                  <span className='header-search-add-parent'>
                    {/* <div className='pages-serach-view'>
                      <CiSearch className='page-search-icon' />
                      <input className='pages-serach' onChange={handleSearch} placeholder='Search Holiday ' type='search' />
                    </div> */}
                    <button className='pages-addempbutton' onClick={openadddep}>Add Holiday</button>
                  </span>
                </div>
                <div className='pages-line'></div>
              </div>
              <div className='pages-filter-options'>
                <div className='pages-filter-sub'>
                  <p>Filter:</p>
                  <Select className='pages-dropdown1' options={companyData}
                    onChange={handleChangeCompany} value={companyFilter} placeholder="Select Work Location" />

                  <Select className='pages-dropdown' options={yearData}
                    onChange={handleChangeYear}
                    value={yearData.find(option => option.value === year)}
                    placeholder="Select Year" />
                  <Select className='pages-dropdown' value={monthData.find(option => option.value === month)} options={monthData} onChange={handleChangeMonth} placeholder="Select Month" />

                </div>
              </div>
              <div className='pages-maincontent'>
                {loading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                )
                  : (
                    <table className='pages-table'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Sl No</th>
                          <th>Holiday From Date</th>
                          <th>Holiday To Date</th>

                          <th>Reason</th>
                          <th>Branch</th>


                        </tr>
                      </thead>
                      <tbody>
                        {holiday && holiday.length > 0 ? (

                          holiday.map((holiday, index) => (
                            <tr key={holiday.id}>
                              <td></td>
                              <td>{(currentpage - 1) * per_page + index + 1}</td> {/* Updated index logic */}
                              <td>{formatDate(holiday.holiday_from_date)}</td>
                              <td>{formatDate(holiday.holiday_end_date)}</td>
                              <td>{holiday.reason}</td>
                              <td>


                                <div className="holiday-dropdown">
                                  <button className="dropbtn">
                                    View
                                    <span className="arrow-down"></span> {/* Arrow added here */}
                                  </button>
                                  <div className="holiday-dropdown-content">
                                    {holiday.company_details.map((company) => (
                                      <p key={company.id}>{company.company_name}</p>
                                    ))}
                                  </div>
                                </div>





                              </td>

                              <td>{holiday.user !== null ? (
                                <span style={{ display: "flex", flexDirection: "row", gap: "20px", fontSize: "14px", alignItems: "center" }}>
                                  {/* <CiEdit onClick={() => editdesigButton(holiday.id)} style={{ color: "#2a7ac7", cursor: "pointer" }} /> */}
                                  <CiTrash onClick={() => showConfirmationModalDelete(holiday.id)} style={{ color: "red", cursor: "pointer" }} />
                                </span>
                              ) : null}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="no-data-table">

                              <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                            </td>
                          </tr>
                        )
                        }
                      </tbody>
                    </table>
                  )}
                <div style={{ float: "right" }} id="pagination_link"></div>
                <div style={{ padding: "20px" }}>
                  {(!loading)&&(totaldedcount > per_page) ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totaldedcount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        forcePage={currentpage - 1} // Adjust index (0-based)

                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Adddesignation
                show={isadddepopen}
                close={closeaddleave}
                sendDataToParent={handleDataFromChild}

            /> */}
      <Addholiday
        show={isadddepopen}
        close={closeaddleave}
        sendDataToParent={handleDataFromChild}

      />
      {/* <Editdesignation
                editData={editData}
                updateData={handleUpdateData}
                show={iseditopen}
                close={closedesign}
            /> */}
      <DeleteAlert
        isOpen={isConfirmationModalOpen}
        message="Really, would you like to remove this Holiday?"
        onCancel={closeConfirmationModal}
        onConfirm={confirmDelete}
      />

    </div>
  );
}

export default HolidayView;
