import React, { useState, useRef, useEffect } from 'react';
import "../../Style/Employeeform.css";
import Sidebar from '../../../Sidebar/Sidebar';
import Header from '../../../Header/Header';
import '../../Style/Addemp.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { convertTo12HourFormat } from '../../../Component/TimeFormat';
import { EmployeeBasicApi, ViewDepHeadwdDepid, ViewDepTlwdDepid } from '../../../../Config/api';
import { useSelector } from 'react-redux';
import { getUserDetails } from '../../../../Services/Storage';
import userdemoimg from '../../../../Images/Pagesimg/userdemo';
// import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import Adddep from '../../../Settings/Department/Adddep';
import Addorgprofile from '../../../Settings/Organisation_profile/Addorgprofile';
import Adddesignation from '../../../Settings/Designation/Adddesignation';
import Adddepartmenttl from '../../../Settings/Departmenttl/Adddepartmenttl';
import Adddepartmenthead from '../../../Settings/Departmenthead/Adddepartmenthead';
import Addleavecomponent from '../../../Leavecomponent/Addleavecomponent';


const Basicdetails = () => {
  const location = useLocation();
  const { name, user_id } = location.state || {};
  const navigate = useNavigate();
  const companies = useSelector((state) => state.companies);
  const designationRedux = useSelector((state) => state.designations);
  const departmentRedux = useSelector((state) => state.department);
  // const departmentHeadRedux = useSelector((state) => state.departmentHead);
  // const departmentTlRedux = useSelector((state) => state.departmentTl);
  const [selecteddep, setselecteddep] = useState(true);
  const [fromvalue, Setfromtime] = useState(null);
  const [tovalue, Settotime] = useState(null);
  const [errors, setErrors] = useState({});
  const user_details = getUserDetails();
  // const company_details = getCompanyDetails();
  const [selectedimg, setselectedimg] = useState(null);
  const [dephdfetch, setdephdfetch] = useState([])
  const [deptlfetch, setdeptlfetch] = useState([])
  const [maxDate, setMaxDate] = useState('');

  const LeaveComponentRedux = useSelector((state) => state.leavecomponents);
  const fileinput = useRef(null);
  const [loading, setloading] = useState(false);
  const handleuploadimg = () => {
    fileinput.current.click();
  }
  useEffect(() => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    setMaxDate(today.toISOString().split('T')[0]);
  }, []);
  const [basicInfo, setBasicInfo] = useState({
    emp_name: name,
    emp_id: "",
    work_email: "",
    father_name: "",
    dob: "",
    gender: "",
    blood_group: "",
    emergency_contact: "",
    doj: "",
    permission_hr: "",
    work_from: "",
    work_to: "",
    work_location: "",
    designation: "",
    department: "",
    depart_head: "",
    depart_tl: "",
    register: user_id,
    user: user_details.id,
    staff_pic: "",
    company: "",
    eligible_leave: [],
  });

  // const handleMultiSelectChange = (selected) => {
  //   const values = Array.from(selected, (option) => option.value);
  //   setBasicInfo((state) => ({
  //     ...state,
  //     eligible_leave: values,
  //   }));
  // };
  const handlefromtimechange = (time) => {
    Setfromtime(time);
    setBasicInfo((state) => ({
      ...state,
      work_from: time + ':00',

    }));

  }
  const handletotimechange = (time) => {
    Settotime(time);
    setBasicInfo((state) => ({
      ...state,
      work_to: time + ':00',
    })

    )
  }
  const [showcompanylist, setcompanylist] = useState(false)
  const [companyname, setcompanyname] = useState("Select Company")
  const [addnewcompany, setnewcompany] = useState(false)
  const companyaddlist = () => {
    setcompanylist((prevValue) => !prevValue);
    setdesignationlist(false);
    setdeptllist(false);
    setdephdlist(false);
    setShowDepartmentList(false);
  }

  const showaddnewcompany = () => {
    setcompanylist(false)
    setnewcompany((prevValue) => !prevValue);

  }
  const closeaddnewcompany = () => {
    setnewcompany(false)
  }
  const handleDataFromCompany = (data) => {
    setBasicInfo((state) => ({
      ...state,
      work_location: String(data.id),
      company: String(data.id)

    }));
    setcompanylist(false);
    // departmentRedux((prev) => [
    //     data,
    //     ...prev,
    // ]);
    setcompanyname(data.company_name);

  };
  useEffect(() => {
    if (!user_id) {

      navigate('/employee'); // Redirect to the employee list page or any other appropriate page
    }
  }, [user_id, navigate]);
  const [showdesignationlist, setdesignationlist] = useState(false);
  const [showleave ,setshowleave] = useState(false);
  const [designationname, setdesignationname] = useState("Select Designation")
  const [addnewdesignation, setnewdesignation] = useState(false)
  const designationaddlist = () => {
    setdesignationlist((prevValue) => !prevValue);
    setcompanylist(false);
    setdeptllist(false);
    setdephdlist(false);
    setShowDepartmentList(false);
  }

  const showaddnewdesignation = () => {
    setdesignationlist(false)
    setnewdesignation((prevValue) => !prevValue);

  }
  const closeaddnewdesignation = () => {
    setnewdesignation(false)
  }
  const handleDataFromdesignation = (data) => {
    setBasicInfo((state) => ({
      ...state,
      designation: String(data.id),

    }));
    setdesignationlist(false);

    setdesignationname(data.designation);

  };
  const [showdeptllist, setdeptllist] = useState(false)
  const [deptlname, setdeptlname] = useState("Select Department Tl")
  const [addnewdeptl, setnewdeptl] = useState(false)
  const deptladdlist = () => {
    setdeptllist((prevValue) => !prevValue);
    setcompanylist(false);
    setdesignationlist(false);
    setdephdlist(false);
    setShowDepartmentList(false);
  }

  const showaddnewdeptl = () => {
    setdeptllist(false)
    setnewdeptl((prevValue) => !prevValue);

  }
  const closeaddnewdeptl = () => {
    setnewdeptl(false)
  }
  const handleDataFromdeptl = (data) => {
    setBasicInfo((state) => ({
      ...state,
      depart_tl: String(data.row_id),

    }));
    setdeptllist(false);

    setdeptlname(data.depart_tl_name);

  };
  const [showdephdlist, setdephdlist] = useState(false)
  const [dephdname, setdephdname] = useState("Select Department Head")
  // const [leavetype, setleavetype] = useState("Select Leave")
  const [addnewdephd, setnewdephd] = useState(false)
  const dephdaddlist = () => {
    setdephdlist((prevValue) => !prevValue);
    setcompanylist(false);
    setdesignationlist(false);
    setdeptllist(false);
    setShowDepartmentList(false);
    setshowleave(false)

  }

  const showaddnewdephd = () => {
    setdeptllist(false)
    setnewdephd((prevValue) => !prevValue);

  }
  const closeaddnewdephd = () => {
    setnewdephd(false)
  }
  const handleDataFromdephd = (data) => {
    setBasicInfo((state) => ({
      ...state,
      depart_head: String(data.row_id),

    }));
    setdephdlist(false);

    setdephdname(data.depart_head_name);

  };
  const [showdepartmentlist, setShowDepartmentList] = useState(false);
  const [showaddnewdepartment, setAddNewdepartment] = useState(false);
  const [departname, setDepartmentName] = useState("Select Department");
  const departmentlist = () => {
    setShowDepartmentList((prevValue) => !prevValue);
    setcompanylist(false);
    setdesignationlist(false);
    setdeptllist(false);
    setdephdlist(false);
    setshowleave(false)

  }

  const addnewdepartment = () => {
    setShowDepartmentList(false)
    setAddNewdepartment((prevValue) => !prevValue);
  }

  const closeaddleave = () => {
    setAddNewdepartment(false);
  }

  const handleDataFromChild = (data) => {
    setBasicInfo((state) => ({
      ...state,
      department: String(data.id),

    }));
    setShowDepartmentList(false);
    // departmentRedux((prev) => [
    //     data,
    //     ...prev,
    // ]);
    setDepartmentName(data.depart_name);

  };
  const fetchdepartmenthead = async (depid) => {
    try {
      const response = await ViewDepHeadwdDepid(`?register_id=${user_details.id}&depart_id=${depid}`)
      if (response) {
        setdephdfetch(response.data.get_depart_head)
      }
    } catch {

    }
  }
  const fetchdepartmenttl = async (depid) => {
    try {
      const response = await ViewDepTlwdDepid(`?register_id=${user_details.id}&depart_id=${depid}`)
      if (response) {
        setdeptlfetch(response.data.get_depart_tl)
      }
    } catch {

    }
  }
  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === 'time') {
      setBasicInfo((state) => ({
        ...state, [name]: convertTo12HourFormat(value),
      }));
    }
    else if (event.target.type === "file") {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        setBasicInfo((state) => ({
          ...state,
          [name]: file,
        }));
        setselectedimg(URL.createObjectURL(file)); // Set the selected image for preview
      } else {
        setselectedimg("");
      }
    }
    // else if (event.target.type === 'select-multiple') {
    //   const values = Array.from(event.target.selectedOptions, (option) => option.value);
    // console.log("Selected multiple values:", values);

    // setBasicInfo(prevState => ({
    //   ...prevState,
    //   eligible_leave: JSON.stringify(values),

    // }));
    // }
    else {
      setBasicInfo((state) => ({
        ...state, [name]: value,
      }));
    }
  }
  const handleKeyPress = (e) => {
    if (e.target.name === 'emp_id') {
      const specialCharPattern = /[!@#$%^&*(),.?":'=;{}|<>+_]/;
      if (specialCharPattern.test(e.key)) {
        e.preventDefault(); // Prevent input
      }
    }
  }

  const handlephonepress = (e) => {
    if (e.target.name === 'emergency_contact') {
      const specialCharPattern = /[^0-9]/g;
      if (specialCharPattern.test(e.key)) {
        e.preventDefault(); // Prevent input
      }
    }
  }

  const inputRefs = {
    emp_id: useRef(null),
    work_email: useRef(null),
    father_name: useRef(null),
    dob: useRef(null),
    gender: useRef(null),
    blood_group: useRef(null),
    emergency_contact: useRef(null),
    doj: useRef(null),
  
  };

  const handleSubmit = async () => {
    console.log(selectedLeaves)
    let newErrors = {};
    // if(basicInfo.emp_name.trim()===""){
    //   newErrors.emp_name = "Name is Required";
    // }else if(!/^[a-zA-Z\s]*$/.test(basicInfo.emp_name)){
    //   newErrors.emp_name = "Name must contain only alphabetics"
    // }
    if (basicInfo.emp_id.trim() === "") {
      newErrors.emp_id = "Employee Id is required";
      inputRefs.emp_id.current.focus();

    }
    if (basicInfo.work_email.trim() === "") {
      newErrors.work_email = "Employee Email is required";
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(basicInfo.work_email.trim())) {
      newErrors.work_email = "Invalid email format";
      inputRefs.work_email.current.focus();

    }

    if (basicInfo.father_name.trim() === "") {
      newErrors.father_name = "Father name is required";
      inputRefs.father_name.current.focus();

    }
    if (basicInfo.dob.trim() === "") {
      newErrors.dob = "Date of Birth is required";
      inputRefs.dob.current.focus();

    }
    if (basicInfo.gender.trim() === "") {
      newErrors.gender = "Please Select Gender";
      inputRefs.gender.current.focus();


    }
    if (basicInfo.blood_group.trim() === "") {
      newErrors.blood_group = "Blood Group is required";
      inputRefs.blood_group.current.focus();

    }
    if (basicInfo.emergency_contact.trim() === "") {
      newErrors.emergency_contact = "Emergency Contact No is required";
    } else if (!/^\d{10}$/.test(basicInfo.emergency_contact)) {
      newErrors.emergency_contact = "Phone Number must be exactly 10 digits";
      inputRefs.emergency_contact.current.focus();

    }
    if (basicInfo.doj.trim() === "") {
      newErrors.doj = "Date of Joining is required";
      inputRefs.doj.current.focus();

    }
    if (basicInfo.work_from.trim() === "") {
      newErrors.work_from = "Work From is required";

    }
    if (basicInfo.work_to.trim() === "") {
      newErrors.work_to = "Work To is required";


    }
    if (basicInfo.work_location.trim() === "") {
      newErrors.work_location = "Select Work Location";

    }
    if (basicInfo.designation.trim() === "") {
      newErrors.designation = "Select Designation";

    }
    if (basicInfo.department.trim() === "") {
      newErrors.department = "Select Department";

    }
    // if (basicInfo.depart_head.trim() === "") {
    //   newErrors.depart_head = "Select Department Head";
    // }
    // if (basicInfo.depart_tl.trim() === "") {
    //   newErrors.depart_tl = "Select Department TL";
    // }
    if (basicInfo.permission_hr.trim() === "") {
      newErrors.permission_hr = "Permission Hour is required";

    }
    if (selectedLeaves.length === 0) {
      newErrors.eligible_leave = "Select Eligible Leave";

    }
    // if (!basicInfo.staff_pic) {
    //   newErrors.staff_pic = "Profile Image is Required"
    // }
    setErrors(newErrors);
    const errorFields = Object.keys(newErrors);
    if (errorFields.length > 0) {
      const firstErrorField = errorFields[0];
      if (inputRefs[firstErrorField]?.current) {
        inputRefs[firstErrorField].current.focus();
      }
      return; // Stop further execution
    }
    
    if (Object.keys(newErrors).length === 0) {
      try {
        setloading(true);
        //console.log("employee basic info json stringfy" , JSON.stringify(basicInfo))
        const data = {
          ...basicInfo,
          eligible_leave:JSON.stringify(selectedLeaves), // Convert only when sending
        };

        if (!data.depart_head.trim()) delete data.depart_head;
        if (!data.depart_tl.trim()) delete data.depart_tl;

        // if (data.depart_tl ==="") {
        //   delete data.depart_tl;
        // }
        const response = await EmployeeBasicApi(data);
        if (response) {
          toast.success('Basic Details added successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });

          navigate('/salarydetails', { state: { name: name, user_id: user_id } });
        }
      }
      catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              // Add each error message to the newErrors object with the field name as the key
              newErrors[key] = errorData[key];
            }
          }


          // Set the errors using setErrors
          setErrors(newErrors);
          const errorFields = Object.keys(newErrors);
          if (errorFields.length > 0) {
            const firstErrorField = errorFields[0];
            inputRefs[firstErrorField]?.current?.focus();
          }

          // setBasicInfo((state) => ({
          //   ...state,
          // }));
        } else {
          toast.error('Failed.Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,

          });
          const newErrors = {
            response_error: [],

          };
          for (const key in error.response.data) {
            if (error.response.data.hasOwnProperty(key)) {
              newErrors.response_error.push(`${error.response.data[key]}`);

            }
          }
          setErrors(newErrors);
          setBasicInfo((state) => ({
            ...state,
          }));
        }
      }
      finally {
        setloading(false)
      }
    }
  }
  const [time, setTime] = useState(null);

  const handleChange = (newTime) => {
    setTime(newTime);
    setBasicInfo((state) => ({
      ...state,
      permission_hr: newTime + ':00',
    })
    )
  };

  const leavetypeselect = () => {
    setshowleave((prevValue) => !prevValue);
    setcompanylist(false);
    setdesignationlist(false);
    setdeptllist(false);
    setShowDepartmentList(false);
  }
  const toggleLeaveSelection = (leaveId) => {
    setSelectedLeaves((prevSelected) => {
      if (prevSelected.includes(leaveId)) {
        // If leave is already selected, remove it
        return prevSelected.filter((id) => id !== leaveId);
      } else {
        // Otherwise, add it
        return [...prevSelected, leaveId];
      }
    });
  };
  const [selectedLeaves, setSelectedLeaves] = useState([]); // State to store selected leaves
  const [isopenaddleavecomp, setopenleavecomp] = useState(false);
  const closeaddleavecomp = () => {
    setopenleavecomp(false)
  }
  const openleavecomp =()=>{
    setopenleavecomp(true)
    setshowleave(false)
  }

  const handleDataFromChildlve = (data) => {
    console.log(data);
  
    // Assuming `data` contains an `id` field or something similar
    if (data && data.id) {
      setSelectedLeaves((prevSelected) => {
        // Check if the leave id from data is already in the array
        if (prevSelected.includes(data.id)) {
          // If it exists, remove it (optional: depending on your use case)
          return prevSelected.filter((id) => id !== data.id);
        } else {
          // Add the new leave id
          return [...prevSelected, data.id];
        }
      });
    }
  }
  return (
    <>
      <div className='index-container'>
        <Header />
        <div className='index-content-parent'>
          <div className='index-content'>
            <Sidebar />
            <div className='index-content-sub'>
              <div className='index-main'>
                <div className='addemp-subheader'>
                  <div className='addemp-subheader-sub'>
                    <div className='addemp-subheader1'>
                      <p>Add Basic Details</p>
                    </div>
                    <div className='addemp-subheader2'>
                      <div className='addemp-stepper'>
                        <div className='addemp-step-indicator'>
                          <div className='addemp-step'>
                            Personal Info
                          </div>
                          <div className='addemp-step addemp-selected' >Basic Details</div>
                          <div className='addemp-step'>Salary Details</div>
                          <div className='addemp-step'>Payment</div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className='addemp-line'></div>
                <div className='addemp-maincontent-sub'>
                  <div className='addemp-form form-padding'>
                    <div className='addemp-form-sub'>
                      <div className='addemp-form-sub-main'>
                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-imgemp'>

                            <div className='emppersonalinfo-input-imgemp-sub'>
                              <div className='emppersonalinfo-input-imgemp-main' onClick={handleuploadimg}>


                                {selectedimg ?
                                  <img src={selectedimg} alt='not_ft' />
                                  :
                                  <img src={userdemoimg.demo_img} alt='not_ft' />
                                }
                                <input type='file' style={{ display: 'none' }}
                                  name='staff_pic' ref={fileinput} onChange={handleInputChange} />

                              </div>

                            </div>
                            <label className='label-fields'>Upload Image <span className='emppersonalinfo-mandatory'>*</span></label>
                            {/* {errors.staff_pic && (<div className='error-message-view'>{errors.staff_pic}</div>)} */}

                          </div>

                        </div>
                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Employee Name <span className='emppersonalinfo-mandatory'>*</span></label>
                            <input className='emppersonalinfo-input-field' readOnly name='emp_name' onChange={handleInputChange} placeholder='Enter employee name' value={name} type='text' />
                          </div>
                          <div className='emppersonalinfo-input-group'>
                            <label>Employee ID  <span className='emppersonalinfo-mandatory'>*</span></label>

                            <input className='emppersonalinfo-input-field' onKeyDown={(e) => {
      if (e.key === ' ') {
        e.preventDefault();
      }
    }} ref={inputRefs.emp_id} onKeyPress={handleKeyPress} name='emp_id' onChange={handleInputChange} value={basicInfo.emp_id} placeholder='Enter employee id' type='text' />
                            {errors.emp_id && (<div className='error-message-view'>{errors.emp_id}</div>)}
                          </div>

                        </div>
                        <div className='basicdetails-input-col'>
                          <div className='basicdetails-col-view'>
                            <label className='label-fields'>Work Email <span className='emppersonalinfo-mandatory'>*</span></label>
                            <input className='emppersonalinfo-inputfield-col' ref={inputRefs.work_email}  onKeyDown={(e) => {
      const regex = /^[a-zA-Z0-9@._\b]+$/; // Allowed characters: alphanumeric, '@', '.', and '_'
      if (!regex.test(e.key)) {
        e.preventDefault(); // Prevents the key press if it's not allowed
      }
    }} placeholder='abc@xyz.com' type='text' value={basicInfo.work_email} name="work_email" onChange={handleInputChange} />
                          </div>
                          {errors.work_email && (<div className='error-message-view'>{errors.work_email}</div>)}
                        </div>

                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Father Name <span className='emppersonalinfo-mandatory'>*</span></label>
                            <input name='father_name'
                             onChange={(e) => {
                              const value = e.target.value;
                              // Allow only letters and spaces, disallow special characters
                              if (/^[a-zA-Z\s]*$/.test(value)) {
                                handleInputChange(e); // Call your existing handler if valid
                              }
                            }}
                            value={basicInfo.father_name}  ref={inputRefs.father_name} className='emppersonalinfo-input-field' type='text' />
                            {errors.father_name && (<div className='error-message-view'>{errors.father_name}</div>)}
                          </div>
                          <div className='emppersonalinfo-input-group'>
                            <label>Date Of Birth  <span className='emppersonalinfo-mandatory'>*</span></label>

                            <input name='dob' value={basicInfo.dob} max={maxDate}  ref={inputRefs.dob} onChange={handleInputChange} className='emppersonalinfo-input-field' type='date' />
                            {errors.dob && (<div className='error-message-view'>{errors.dob}</div>)}
                          </div>

                        </div>
                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Gender <span className='emppersonalinfo-mandatory'>*</span></label>
                            <select name="gender" onChange={handleInputChange} value={basicInfo.gender}  ref={inputRefs.gender} className='emppersonalinfo-input-field3'>
                              <option value=''>Select Gender</option>
                              <option value='male'>Male</option>
                              <option value='female'>Female</option>
                              <option value='other'>Other</option>
                            </select>
                            {errors.gender && (<div className='error-message-view'>{errors.gender}</div>)}
                          </div>
                          <div className='emppersonalinfo-input-group'>
                            <label>Blood Group  <span className='emppersonalinfo-mandatory'>*</span></label>

                            <input name='blood_group' onChange={handleInputChange}  ref={inputRefs.blood_group} value={basicInfo.blood_group}
                              className='emppersonalinfo-input-field' type='text' />
                            {errors.blood_group && (<div className='error-message-view'>{errors.blood_group}</div>)}
                          </div>

                        </div>

                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Emergency Contact No <span className='emppersonalinfo-mandatory'>*</span></label>
                            <input name='emergency_contact' onChange={handleInputChange} onKeyPress={handlephonepress}  ref={inputRefs.emergency_contact} value={basicInfo.emergency_contact} className='emppersonalinfo-input-field' type='text' />
                            {errors.emergency_contact && (<div className='error-message-view'>{errors.emergency_contact}</div>)}
                          </div>
                          <div className='emppersonalinfo-input-group'>
                            <label>Date Of Joining  <span className='emppersonalinfo-mandatory'>*</span></label>
                            <input name='doj'  ref={inputRefs.doj} value={basicInfo.doj} onChange={handleInputChange} className='emppersonalinfo-input-field' type='date' />
                            {errors.doj && (<div className='error-message-view'>{errors.doj}</div>)}
                          </div>
                        </div>



                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Permission Hour <span className='emppersonalinfo-mandatory'>*</span></label>
                            {/* <input name='permission_hr' onChange={handleInputChange} value={basicInfo.permission_hr} className='emppersonalinfo-input-field' type='time' /> */}
                            <TimePicker
                              onChange={handleChange}
                              value={time}
                              className="time-picker"
                              
                              format="HH:mm" // Internal format is 24-hour
                              clockIcon={null} // Optional: Hide the default clock icon
                            />
                            {errors.permission_hr && (<div className='error-message-view'>{errors.permission_hr}</div>)}
                          </div>
                          {/* <div className='emppersonalinfo-input-group'>
<label>Leave Eligible<span className='emppersonalinfo-mandatory'>*</span></label>

<select className='emppersonalinfo-input-field'  multiple name='eligible_leave' onChange={handleInputChange} >
  {
    LeaveComponentRedux.map((leavecomponent)=>(
      <option value={leavecomponent.id}> {leavecomponent.leave_type} </option>
    ))
  }
  
</select>
</div> */}
                          <div className='emppersonalinfo-input-group'>
                            <label>Leave Eligible <span className='emppersonalinfo-mandatory'>*</span></label>
                            {/* <MultiSelect
                              options={LeaveComponentRedux.map(leavecomponent => ({
                                label: leavecomponent.leave_type,
                                value: leavecomponent.id
                              }))}
                              value={basicInfo.eligible_leave.map(id => ({
                                label: LeaveComponentRedux.find(leave => leave.id === id)?.leave_type,
                                value: id
                              }))}
                              onChange={handleMultiSelectChange}
                              labelledBy="Select Leave"
                            /> */}
                                                       <div className="adddept-div">
      <span className="adddept-div-span" onClick={leavetypeselect}>
        {selectedLeaves.length > 0
          ? `Selected (${selectedLeaves.length})`
          : 'Select Leave Types'}
      </span>
      {showleave && (
        <div className="add-dept-ul-div">
          {companies.length > 0 ? (
            <ul>
              {LeaveComponentRedux.map((leave, index) => (
                <li
                  key={index}
                  value={leave.id}
                  className={selectedLeaves.includes(leave.id) ? 'selected' : ''}
                  onClick={() => toggleLeaveSelection(leave.id)}
                >
                  {selectedLeaves.includes(leave.id) ? '✓ ' : ''} {leave.leave_type}
                </li>
              ))}
            </ul>
          ) : (
            <p>No Leave found</p>
          )}
          <div className="add-dept-footer">
           
            <div onClick={openleavecomp} className="add-dept-new-option">
              <span>Add New Leave</span>
            </div>
          </div>
        </div>
      )}
    </div>
                            {errors.eligible_leave && (<div className='error-message-view'>{errors.eligible_leave}</div>)}
                          </div>
                        </div>


                        <p className='emp-work-p'>Work Timing:</p>
                        <div className='emppersonalinfo-input-row'>

                          <div className='emppersonalinfo-input-group'>
                            <label>From Time <span className='emppersonalinfo-mandatory'>*</span></label>
                            {/* <input name='work_from' onChange={handleInputChange} value={basicInfo.work_from} className='emppersonalinfo-input-field' type='time' /> */}
                            <TimePicker
                              onChange={handlefromtimechange}
                              value={fromvalue}
                              className="time-picker"

                            />
                            {errors.work_from && (<div className='error-message-view'>{errors.work_from}</div>)}
                          </div>
                          <div className='emppersonalinfo-input-group'>
                            <label>To Time <span className='emppersonalinfo-mandatory'>*</span></label>
                            {/* <input name='work_to' onChange={handleInputChange} value={basicInfo.work_to} className='emppersonalinfo-input-field' type='time' /> */}
                            <TimePicker
                              onChange={handletotimechange}
                              value={tovalue}
                              className="time-picker"

                            />
                            {errors.work_to && (<div className='error-message-view'>{errors.work_to}</div>)}
                          </div>
                        </div>
                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Work Location <span className='emppersonalinfo-mandatory'>*</span></label>
                            {/* <select name='work_location' value={basicInfo.work_location} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                            <option value=''>Select Location</option>
                            {companies.map((company, index) => (
                              <option key={index} value={company.id}>
                                {company.company_name}
                              </option>
                            ))}
                          </select> */}
                            <div className='adddept-div'>
                              <span className='adddept-div-span' onClick={companyaddlist}>
                                {companyname}
                              </span>
                              {
                                showcompanylist ?
                                  (
                                    <div className='add-dept-ul-div'>
                                      {companies.length > 0 ? (

                                        <ul>

                                          {companies.map((company, index) => (
                                            <li key={index} value={company.id}
                                              onClick={() => {
                                                setBasicInfo((state) => ({
                                                  ...state,
                                                  work_location: String(company.id), // Set department name or ID based on your structure
                                                  company: String(company.id)
                                                }));
                                                setcompanyname(company.company_name)
                                                setcompanylist((prevValue) => !prevValue); // Toggle department list visibility
                                              }}
                                            >
                                              {company.company_name}
                                            </li>
                                          ))}


                                        </ul>
                                      ) : (
                                        <p>No company found</p> // Display message when the list is empty
                                      )}
                                      <div onClick={showaddnewcompany} className='add-dept-new-option'>
                                        <span>Add New Company</span>
                                      </div>
                                    </div>
                                  )
                                  :
                                  null
                              }
                            </div>
                            {errors.work_location && (<div className='error-message-view'>{errors.work_location}</div>)}
                          </div>
                          <div className='emppersonalinfo-input-group'>
                            <label>Designation
                              <span className='emppersonalinfo-mandatory'>*</span></label>

                            {/* <select name='designation' value={basicInfo.designation} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                            <option value=''>Select Designation</option>
                            {designationRedux.map((designation, index) => (
                              <option key={index} value={designation.id}>
                                {designation.designation}
                              </option>
                            ))}
                          </select> */}
                            <div className='adddept-div'>
                              <span className='adddept-div-span' onClick={designationaddlist}>
                                {designationname}
                              </span>
                              {
                                showdesignationlist ?
                                  (
                                    <div className='add-dept-ul-div'>
                                      {companies.length > 0 ? (

                                        <ul>

                                          {designationRedux.map((designation, index) => (
                                            <li key={index} value={designation.id}
                                              onClick={() => {
                                                setBasicInfo((state) => ({
                                                  ...state,
                                                  designation: String(designation.id), // Set department name or ID based on your structure
                                                }));
                                                setdesignationname(designation.designation)
                                                setdesignationlist((prevValue) => !prevValue); // Toggle department list visibility
                                              }}
                                            >
                                              {designation.designation}
                                            </li>
                                          ))}


                                        </ul>
                                      ) : (
                                        <p>No designation found</p> // Display message when the list is empty
                                      )}
                                      <div onClick={showaddnewdesignation} className='add-dept-new-option'>
                                        <span>Add New Designation</span>
                                      </div>
                                    </div>
                                  )
                                  :
                                  null
                              }
                            </div>
                            {errors.designation && (<div className='error-message-view'>{errors.designation}</div>)}
                          </div>

                        </div>
                        <div className='emppersonalinfo-input-row'>
                          <div className='emppersonalinfo-input-group'>
                            <label>Department <span className='emppersonalinfo-mandatory'>*</span></label>
                            <div className='adddept-div'>
                              <span className='adddept-div-span' onClick={departmentlist}>
                                {departname}
                                {/* <input type='text' name='department' value={basicInfo.department} /> */}
                              </span>
                              {
                                showdepartmentlist ?
                                  (
                                    <div className='add-dept-ul-div'>
                                      {departmentRedux.length > 0 ? (

                                        <ul>

                                          {departmentRedux.map((department, index) => (
                                            <li key={index} value={department.id}
                                              onClick={() => {
                                                setBasicInfo((state) => ({
                                                  ...state,
                                                  department: String(department.id), // Set department name or ID based on your structure
                                                }));
                                                setDepartmentName(department.depart_name);
                                                setShowDepartmentList((prevValue) => !prevValue); // Toggle department list visibility
                                                setselecteddep(false);
                                                fetchdepartmenthead(department.id);
                                                fetchdepartmenttl(department.id);
                                              }}
                                            >
                                              {department.depart_name}
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        <p>No departments found</p> // Display message when the list is empty
                                      )}
                                      <div onClick={addnewdepartment} className='add-dept-new-option'>
                                        <span>Add New Department</span>
                                      </div>
                                    </div>
                                  )
                                  :
                                  null
                              }

                            </div>

                            {/* <select name='department' value={basicInfo.department} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                            <option value=''>Select Department</option>
                            {departmentRedux.map((department, index) => (
                              <option key={index} value={department.id}>
                                {department.depart_name}
                              </option>
                            ))}
                            <option> Add New Department  </option>
                          </select> */}
                            {errors.department && (<div className='error-message-view'>{errors.department}</div>)}
                          </div>
                          <div className={`emppersonalinfo-input-group ${selecteddep ? 'addemp-sel-disabled' : ''}`}>

                            {/* <div className='emppersonalinfo-input-group'> */}
                            <label>Department Head <span className='emppersonalinfo-mandatory'>*</span></label>
                            {/* <select name='depart_head' value={basicInfo.depart_head} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                            <option value=''>Select Department Head</option>
                            {departmentHeadRedux.map((department_head, index) => (
                              <option key={index} value={department_head.row_id}>
                                {department_head.depart_head_name}
                              </option>
                            ))}
                          </select> */}
                            <div className='adddept-div'>
                              <span className='adddept-div-span' onClick={dephdaddlist}>
                                {dephdname}
                              </span>
                              {
                                showdephdlist ?
                                  (
                                    <div className='add-dept-ul-div'>
                                      {companies.length > 0 ? (

                                        <ul>

                                          {dephdfetch.map((department_head, index) => (
                                            <li key={index} value={department_head.row_id}
                                              onClick={() => {
                                                setBasicInfo((state) => ({
                                                  ...state,
                                                  depart_head: String(department_head.row_id), // Set department name or ID based on your structure
                                                }));
                                                setdephdname(department_head.depart_head_name)
                                                setdephdlist((prevValue) => !prevValue); // Toggle department list visibility
                                              }}
                                            >
                                              {department_head.depart_head_name}
                                            </li>
                                          ))}


                                        </ul>
                                      ) : (
                                        <p>No department Head found</p> // Display message when the list is empty
                                      )}
                                      <div onClick={showaddnewdephd} className='add-dept-new-option'>
                                        <span>Add New Department Head</span>
                                      </div>
                                    </div>
                                  )
                                  :
                                  null
                              }
                            </div>
                            {errors.depart_head && (<div className='error-message-view'>{errors.depart_head}</div>)}
                          </div>
                          <div className={`emppersonalinfo-input-group ${selecteddep ? 'addemp-sel-disabled' : ''}`}>
                            <label>Department TL
                              <span className='emppersonalinfo-mandatory'>*</span></label>

                            {/* <select name='depart_tl' value={basicInfo.depart_tl} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                            <option value=''>Select Department TL</option>
                            {departmentTlRedux.map((department_tl, index) => (
                              <option key={index} value={department_tl.row_id}>
                                {department_tl.depart_tl_name}
                              </option>
                            ))}
                          </select> */}
                            <div className='adddept-div'>
                              <span className='adddept-div-span' onClick={deptladdlist}>
                                {deptlname}
                              </span>
                              {
                                showdeptllist ?
                                  (
                                    <div className='add-dept-ul-div'>
                                      {companies.length > 0 ? (

                                        <ul>

                                          {deptlfetch.map((department_tl, index) => (
                                            <li key={index} value={department_tl.row_id}
                                              onClick={() => {
                                                setBasicInfo((state) => ({
                                                  ...state,
                                                  depart_tl: String(department_tl.row_id), // Set department name or ID based on your structure
                                                }));
                                                setdeptlname(department_tl.depart_tl_name)
                                                setdeptllist((prevValue) => !prevValue); // Toggle department list visibility
                                              }}
                                            >
                                              {department_tl.depart_tl_name}
                                            </li>
                                          ))}


                                        </ul>
                                      ) : (
                                        <p>No department tl found</p> // Display message when the list is empty
                                      )}
                                      <div onClick={showaddnewdeptl} className='add-dept-new-option'>
                                        <span>Add New Department Tl</span>
                                      </div>
                                    </div>
                                  )
                                  :
                                  null
                              }
                            </div>
                            {errors.depart_tl && (<div className='error-message-view'>{errors.depart_tl}</div>)}

                          </div>
                        </div>
                        {errors.response_error && (<div className='error-message-view'>{errors.response_error}</div>)}
                        <div className='emppersonalinfo-submitbutton'>


                          <button onClick={handleSubmit} disabled={loading}>{loading ? <span className='spinner'></span> : 'Submit'}</button>
                        </div>
                        <div></div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Adddep
        show={showaddnewdepartment}
        close={closeaddleave}
        sendDataToParent={handleDataFromChild}
      />
      <Addorgprofile
        show={addnewcompany}
        close={closeaddnewcompany}
        sendDataToParent={handleDataFromCompany}
      />
      <Adddesignation
        show={addnewdesignation}
        sendDataToParent={handleDataFromdesignation}
        close={closeaddnewdesignation}
      />
      <Adddepartmenttl
        show={addnewdeptl}
        close={closeaddnewdeptl}
        sendDataToParent={handleDataFromdeptl}
      />
      <Adddepartmenthead
        show={addnewdephd}
        close={closeaddnewdephd}
        sendDataToParent={handleDataFromdephd}
      />
       <Addleavecomponent
        show={isopenaddleavecomp}
        close={closeaddleavecomp}
        sendDataToParent={handleDataFromChildlve}
      />
    </>


  )
}

export default Basicdetails

