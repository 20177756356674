import React, { useState, useEffect, useCallback } from 'react';
import { CiSearch, CiTrash, CiEdit } from 'react-icons/ci';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
// import departmentList from '../../../JsonData/DepartmentList';
import Adddep from './Adddep';
import { DeletedepcompApi, DeletedepcompconfirmApi, FetchDepartmentcomp } from '../../../Config/api';
import { getUserDetails } from '../../../Services/Storage';
import Editdep from './Editdep';
import DeleteAlert from '../../popup/DeleteAlert';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import pageloader from '../../../Loader/pageloading/Pageloader';
import ReactPaginate from "react-paginate";
import { Store } from '../../../Store';
import { deleteDepartmentComp } from '../../../Slice/departmentSlice';
import Confirmationwidbtn from '../../Component/Confirmationwidbtn';

const Viewdepartment = () => {
    const user_details = getUserDetails();
    const [departmentList, setdepartmentcomp] = useState([])
    const [isadddepopen, setadddepopen] = useState(false);
    const [iseditopen, seteditopen] = useState(false)
    const [editData, setEditData] = useState([]);
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [deleteComponentId, setDeleteComponentId] = useState(null);
    const [loading, setIsloading] = useState(false);
    const [totaldedcount, setdedcount] = useState(0)
    const per_page = 10;
    const [currentpage, setCurrentPage] = useState(1);
    const [search, setSearchword] = useState("");
    const [deldepartment, setdepartment] = useState(false);
    const [deleteComponentData, setDeleteComponentData] = useState([]); // New state to hold data


    const currentPageNo = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };

    const handleSearch = (event) => {
        setSearchword(event.target.value);
    }
    const openadddep = () => {
        setadddepopen(true);
    }
    const closeaddleave = () => {
        setadddepopen(false);
    }


    const Fetchdepartmentcomp = useCallback(async () => {
        try {
            setIsloading(true);

            const response = await FetchDepartmentcomp(`?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}`);
            if (response) {
                setdepartmentcomp(response.data.get_department || [])
                setdedcount(response.data.page_context.total)

            }
        } catch (error) {

        } finally {
            setIsloading(false)
        }
    }, [currentpage, per_page, search, user_details.id])
    useEffect(() => {
        Fetchdepartmentcomp();
    }, [Fetchdepartmentcomp])

    const closeeditlevae = () => {
        seteditopen(false)
    }
    const handleUpdateData = (data) => {
        const updatedList = departmentList.map((comp) =>
            comp.id === data.id ? data : comp
        );
        setdepartmentcomp(updatedList);
    }
    const editleaveButton = (componentid) => {
        const updatedList = departmentList.filter((component) =>
            component.id === componentid
        );
        if (updatedList) {
            setEditData(updatedList[0]);
            seteditopen(true);

        }
    }
    const showConfirmationModalDelete = (delid) => {
        setDeleteComponentId(delid);
        setConfirmationModalOpen(true);

    }
    const closeConfirmationModal = () => {
        // setDeleteComponentId(null);
        setConfirmationModalOpen(false);
    };

    const confirmDelete = () => {
        if (deleteComponentId) {
            deletesalaryComponent(deleteComponentId, false);
            closeConfirmationModal();
        }
    };
    const deletesalaryComponent = async (componentId, deleteConf) => {

        try {
            if (deleteConf === false) {
                const response = await DeletedepcompApi(componentId);
                if (response) {
                    const updatedList = departmentList.filter((component) => component.id !== componentId);
                    setdepartmentcomp(updatedList);
                    Store.dispatch(deleteDepartmentComp(componentId));

                }
            } else {

                const response = await DeletedepcompconfirmApi(`${componentId}/?confirm=${true}`);
                if (response) {
                    const updatedList = departmentList.filter((component) => component.id !== componentId);
                    setdepartmentcomp(updatedList);
                    Store.dispatch(deleteDepartmentComp(componentId));

                }
            }

        }
        catch (error) {
            setdepartment(true)

            if (error.response && error.response.status === 422) {


                setDeleteComponentData(error.response.data);

                setdepartment(true)

            }

        }
    }
    const handleDataFromChild = (data) => {
        setdepartmentcomp((prev) => [
            data,
            ...prev,
        ]);
    };
    // const department = departmentList.get_department;
    const confideletealt = () => {

        if (deleteComponentId) {

            closedelconfir();
            deletesalaryComponent(deleteComponentId, true);


        }
    }
    const closedelconfir = () => {
        setdepartment(false);
        setDeleteComponentId(null);

    }

    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='pages-subheader'>
                                <div className='pages-subheader-sub-1'>
                                    <p>Department</p>
                                    <span className='header-search-add-parent'>
                                        <div className='pages-serach-view'>
                                            <CiSearch className='page-search-icon' />
                                            <input className='pages-serach' onChange={handleSearch} placeholder='Search Department ' type='search' />
                                        </div>
                                        <button className='pages-addempbutton' onClick={openadddep}>Add Department</button>
                                    </span>
                                </div>
                                <div className='pages-line'></div>
                            </div>
                            <div className='pages-maincontent'>
                                {loading ? (
                                    <div className="loader-container">
                                        <img src={pageloader.page_loader} alt="Loading..." />
                                    </div>
                                )
                                    : (
                                        <table className='pages-table'>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Sl No</th>
                                                    <th>Department</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {departmentList && departmentList.length > 0 ? (

                                                    departmentList.map((department, index) => (
                                                        <tr key={department.id}>
                                                            <td></td>
                                                            <td>{(currentpage - 1) * per_page + index + 1}</td> {/* Updated index logic */}
                                                            <td>{department.depart_name}</td>
                                                            <td>{department.user !== null ? (
                                                                <span style={{ display: "flex", flexDirection: "row", gap: "20px", fontSize: "14px", alignItems: "center" }}>
                                                                    <CiEdit onClick={() => editleaveButton(department.id)} style={{ color: "#2a7ac7", cursor: "pointer" }} />
                                                                    <CiTrash onClick={() => showConfirmationModalDelete(department.id)} style={{ color: "red", cursor: "pointer" }} />
                                                                </span>
                                                            ) : null}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8} className="no-data-table">

                                                            <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </table>
                                    )}
                                <div style={{ float: "right" }} id="pagination_link"></div>
                                <div >
                                    {(!loading)&&(totaldedcount > per_page) ? (
                                        <>
                                            <ReactPaginate
                                                pageCount={Math.ceil(totaldedcount / per_page)}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={2}
                                                onPageChange={currentPageNo}
                                                containerClassName={"pagination"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                forcePage={currentpage - 1} // Adjust index (0-based)

                                            />
                                        </>
                                    ) : (
                                        <> </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Adddep
                show={isadddepopen}
                close={closeaddleave}
                sendDataToParent={handleDataFromChild}

            />
            <Editdep
                editData={editData}
                updateData={handleUpdateData}
                show={iseditopen}
                close={closeeditlevae}

            />
            <DeleteAlert
                isOpen={isConfirmationModalOpen}
                message="Really, would you like to remove this Component?"
                onCancel={closeConfirmationModal}
                onConfirm={confirmDelete}
            />
            <Confirmationwidbtn
                close={closedelconfir}
                onConfirm={confideletealt}
                open={deldepartment}
                data={deleteComponentData}
            />
        </div>
    );
}

export default Viewdepartment;
